import { render, staticRenderFns } from "./TicketsHistory.vue?vue&type=template&id=2974d09e&scoped=true"
import script from "./TicketsHistory.vue?vue&type=script&lang=js"
export * from "./TicketsHistory.vue?vue&type=script&lang=js"
import style0 from "./TicketsHistory.vue?vue&type=style&index=0&id=2974d09e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2974d09e",
  null
  
)

export default component.exports